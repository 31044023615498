#projectServerVirtualization {
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    #container {
        height: 100%;
        max-width: 1240px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 15px;

        #topImage {
            img {
                width: 100%;
            }
        }
        #content {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 20px 0 50px 0;

            #image-wrapper {
                text-align: center;
                margin-top: 20px;

                img {
                    width: 40%;
                }
            }

            #content-right {
                .intro {
                    .title {
                        font-weight: bold;
                        color: black;
                    }
                    .description {
                        color: #565656;
                    }
                }
            }
        }
    }
}
