body{
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
}

.no-header-page{
  padding-top: 123px;


}

.no-header-page .main{
  margin: 1000px 1000px 1000px 1000px;
}