@font-face {
  font-family: 'JosefinSans-Regular.ttf';
  src: url('./fonts/JosefinSans-Regular.ttf');
}
@font-face {
  font-family: 'JosefinSans-Bold.ttf';
  src: url('./fonts/JosefinSans-Bold.ttf');
}
@font-face {
  font-family: 'JosefinSans-BoldItalic.ttf';
  src: url('./fonts/JosefinSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'JosefinSans-Italic.ttf';
  src: url('./fonts/JosefinSans-Italic.ttf');
}
@font-face {
  font-family: 'JosefinSans-Light.ttf';
  src: url('./fonts/JosefinSans-Light.ttf');
}
@font-face {
  font-family: 'JosefinSans-LightItalic.ttf';
  src: url('./fonts/JosefinSans-LightItalic.ttf');
}
@font-face {
  font-family: 'JosefinSans-SemiBold.ttf';
  src: url('./fonts/JosefinSans-SemiBold.ttf');
}
@font-face {
  font-family: 'JosefinSans-SemiBoldItalic.ttf';
  src: url('./fonts/JosefinSans-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'JosefinSans-Thin.ttf';
  src: url('./fonts/JosefinSans-Thin.ttf');
}
@font-face {
  font-family: 'JosefinSans-ThinItalic.ttf';
  src: url('./fonts/JosefinSans-ThinItalic.ttf');
}

body {
  margin: 0;
  font-family: JosefinSans;

}


