#iptransit {
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    #container {
        height: 100%;
        max-width: 1240px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 15px;

        #topImage {
            width: 100%;
            img {
                width: 100%;
            }
        }

        .intro {
            margin-top: 20px;
        }

        .description {
            color: #565656;
            margin: 0 !important;
        }

        #content {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin: 20px 0 50px 0;

            img {
                width: 40%;
                margin: 0 0 0 20px;
            }
        }
    }
}
