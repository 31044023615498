.menu-item{
    padding: 0 10px;
    font-size: 16px;
    white-space: nowrap;
    display: inline-block;
    
    p{
        //padding: 10px;
        margin: 0;
    }

    &:hover{
        color: #C91515;
        cursor: pointer;
    }

    .options{
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;
        color: #A4A4A4;
        &:hover{
            color: #C91515;
            cursor: pointer;
        }

    }
    .selected{
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;
        color: #C91515;
        &:hover{
            cursor: pointer;
        }
    }
}