.content{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    .contain-left{
        width: 70%;
        .details{
            margin: 20px;
            color: #565656;
            font-weight: bold;
        }
    }
    .contain-right{
        width: 30%;
        height: 300px;
        .map{
            height: 100%;
        }
    }
}