.aboutUs{
    width: 100%;
    height: 100%;
    max-width: 1240px;
    padding: 0px 15px;
    margin: 0 auto;
    box-sizing: border-box;
    .container{
        padding: 20px 15px 40px 15px;
        box-sizing: border-box;
        .description{
            color: black;
            margin-bottom: 10px;
            font-size: 20px;
            color: #666666;
            h2{
                font-weight: bold;
                font-size: 24px;
                color: black;
            }
        }
        .break{
            height: 100px;
        }
        
    }
}