.serviceList{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .wrap{
        width: 25%;
        .block{
            width: 95%;
            padding: 0 0px;
            text-align: center;

            img{
                width: 80%;
                margin-bottom: 10px;
            }
            .title{
                color: black;
                text-align: center;
                width: 95%;
                margin-bottom: 10px;
                p{
                    font-size: 18px;
                }
                a{
                    color: black;
                    &:hover{
                        color: red;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}