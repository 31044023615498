.clients{
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    .container{
        .clients-header {
            margin: auto;
            width: 100%;
            #title {
                margin-left: 100px;
            }
            #line {
                border-bottom: 2px solid grey;
            }
        }
        .content-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 50px;
            .row {
                display: flex;
                justify-content: center;
            }
        }
        #hkbn-logo {
            width: 15%;
            align-self: center;
            margin: 0 40px 0 40px;
            //width: 200px;
            //height: 100px;
        }
        #hkia-logo {
            width: 20%;
            align-self: center;
            margin: 0 40px 0 40px;
            //width: 300px;
        }
        #hkma-logo {
            width: 30%;
            align-self: center;
            margin: 0 40px 0 0px;
        }
        #meriki-logo {
            width: 15%;
            align-self: center;
            margin: 0 40px 0 0px;
        }
        #hit-logo {
            width: 20%;
            align-self: center;
            margin: 0 80px 0 40px;
        }
        .break{
            height: 100px;
        }
    }
}