.MobileApplication{
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    .container{
        height: 100%;
        max-width: 1240px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 15px;

        .topImage{
            width: 100%;
            img{
                width:100%;
            }
        }
        .content{
            width: 100%;
            display: flex;
            flex-direction: column;

            .intro{
                .title{
                    line-height: 50px;
                    font-weight: bold;
                    color:black;

                }
                .description{
                    color: #565656;
                }
            }
            .middle-button{
                padding-top: 10px;
                padding-bottom: 10px;
                margin-left: 0px;
                margin-right: 0px;
                text-align: center;
            }
            .break{
                height: 310px;
            }
        }
    }
}