.left{
    position: relative;
    //width: 20%;
    width: 25.818181818182%;
    //padding: 0 15px;
    .menu{
        width: 100%;
        box-sizing: border-box;
        background-color: rgba(213,213,213,0.5);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 10px;

        .name{
            font-size: 16px;
            font-weight: bold;
            margin: 20px 0px 5px 10%;
        }
        .menu-item{
            margin-left: 12%;
            display: inline-flex;
            flex-direction: column;
            white-space: nowrap;
            .items{
                color: black;
                margin-bottom: 10px;
                font-size: 15px;
            }
            a{
                &:hover{
                    color: red;
                    cursor: pointer;
                }
            }
        }
    }
}