.questionnaire{
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    .container{
        height: 100%;
        max-width: 1240px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 15px;

        .topImage{
            width: 100%;
            text-align: center;
            img{
                width:50%;
            }
        }
        .content{
            width: 100%;
            display: flex;
            flex-direction: column;

            .break-space{
                height: 20px;
            }

            .title{
                font-size: 24px;
                font-weight: bold;
                color:black;
                //color: #006699;
            }
            .description{
                margin-bottom: 0;
                color: #565656;
                p{
                    font-size: 20px;
                }
            }

            Label{
                font-weight: bold;
            }

            .form-label{
                font-weight: normal;
                font-style: italic;
            }

            .form-required{
                display: inline;
                color: #ff2825;
            }

            .inline-group{
                display: flex;
                justify-content: space-between;
            }

            .input-group{
                
                margin-bottom: 10px;
            }

            .checkbox-form{
                display: flex;
                flex-direction: column;

                .checkbox-row{
                    margin-left: 22px;
                    span{
                        margin-left: 20px;
                        font-weight: normal;
                    }
                }
            }

            .radio-row{
                span{
                    font-weight: normal;
                    margin-left: 20px;
                }
            }
        }
    }
}