.photocopier{
    width: 100%;
    .container{
        display: flex;
        flex-direction: row;
        max-width: 1240px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
        .right{
            margin-left: 30px;
            width: 90%;
            .title{
                font-size: 24px;
                font-weight: bold;
            }
        }
    }
    .break{
        height: 20px;
    }
}