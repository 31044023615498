.footer{
    background-color: #212121;

    .container{
        max-width: 1240px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;

        .content{
            //font-family: 'Karla', sans-serif;
            padding: 58px 0;
            color: #ffffff;

            h2{
                margin-bottom: 0.888em;
                font-size: 18px;
                line-height: 1;
                color: #ffffff;
                margin: 0;
                padding: 0;
            }

            .upper{
                //margin: 0px -15px 21px -15px;
                margin-bottom: 50px;;
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 100%;

                .location{
                    //width: 26.008968609865%;
                    padding: 0px 15px;
                    width: 20%;
                }
                .contactUs{
                    //width: 73.991031390135%;
                    padding: 0px 15px;
                    width: 32%;
                    blockquote{
                        display: flex;
                        flex-direction: column;
                        color: rgb(255, 255, 255);
                        margin: 10px 0;
                        padding-left: 20px;
                        border-left: 4px solid #ddd;
                        font-style: italic;
                        line-height: 170%;

                        span{
                            font-size: 15px;
                        }
                    }
                    img{
                        height: 59px;
                        width: 191px;
                        margin-bottom: 10px;
                    }
                }
                .upcomingEvent{
                    width: 20%;
                    h2{
                        text-align: center !important;
                    }
                }
                .socialMedia{
                    width: 23%;
                    vertical-align: middle;
                    text-align: center;
                    margin-top: 10px;
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 12px;
                    }
                }

                .item{
                    h2{
                        text-align: left;
                        margin-bottom: 16px;
                    }
                }
            }

            .lower{
                h2{
                    text-align: center;
                    font-size: small;
                }
            }
        }
    }
}