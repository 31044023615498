.contactUs{
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    .container{
        height: 100%;
        max-width: 1240px;
        margin: 0 auto;
        box-sizing: border-box;

        .banner{
            width: 100%;
            text-align: center;
            img{
                width: 90%;
            }
        }

        .wrap{
            padding: 0 30px;
        }

        .break{
            height: 100px;
        }
    }
}