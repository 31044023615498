#WifiService {
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    #container {
        height: 100%;
        max-width: 1240px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 15px;

        #topImage {
            width: 100%;
            img {
                width: 100%;
            }
        }
        #content {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-bottom: 50px;

            #content-left {
                .intro {
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        color: black;
                        line-height: 60px;
                    }
                    .description {
                        color: #565656;
                    }
                    p {
                        margin: 0 !important;
                    }
                }
            }
            img {
                width: 40%;
                margin: 20px 0 0 20px;
            }
        }
    }
}
