.product{
    position: relative;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
    .container{
        height: 100%;
        max-width: 1240px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;

        .topImage{
            width: 100%;
            img{
                width:100%;
                margin-bottom: 28px;
            }
        }

        .content{
            width: 100%;
            display: flex;
            flex-direction: row;

            .right{
                width: 90%;
                margin-left: 15px;
                //width: 74.181818181818%;
                padding: 0 15px;
                .intro{
                    .title{
                        font-size: 24px;
                        font-weight: bold;
                        color:black;
                    }
                    .description{
                        font-size: 20px;
                        //font-weight: bold;
                        color: #666666;
                    }
                }
                .middle-button{
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                    text-align: center;
                }
                .break{
                    height: 100px;
                }

                .content{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    .left{
                        width: 70%;
                        .details{
                            margin: 20px;
                            color: #565656;
                            font-weight: bold;
                        }
                    }
                    .right{
                        width: 30%;
                        .map{
                            height: 280px;
                        }
                    }
                }

                .productList{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: center;

                    .product{
                        width: 23%;
                        padding: 0 15px;
                        text-align: center;
                        img{
                            width: 100%;
                        }
                        span{
                            font-size: 17px;
                            margin-top: 18px;
                            margin-bottom: 25px;
                            color: #666666;
                            font-weight: bold;
                        }
                        .product-image-container{
                            width: 175px;
                            height: 156px;
                            //vertical-align: middle;
                            display: table-cell;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}