.menuBar{
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    background: #ffffff;

   // text-align: right;
    //padding-top: 4px;
   //padding-bottom: 4px;
    //margin:0;


    .container{
        height: 100%;
        max-width: 1240px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;

        .header-inner-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .icon{
                img{
                    width: 30%;
                    
                }
            }

            .nav-wrap{
                display: flex;
                flex-direction: row;
                overflow: hidden;
                max-width: 80%;
                padding: 10px 0;
                background: #ffffff;
                text-align: center;
                //-webkit-backface-visibility: hidden;
            }
        }

        .header-inner-wrap-sub{
            display: block;
            //justify-content: space-between;
            //align-items: center;
            width: 100%;
            text-align: right;

            .nav-wrap-sub{
                //display: flex;
                //flex-direction: row-reverse;
                //overflow: hidden;
                padding: 12px 5px 18px;
                //max-width: 80%;
                padding-top: 0px;
                //padding-bottom: 30px;
                background: #ffffff;
                //text-align: center;
                //-webkit-backface-visibility: hidden;
            }
        }
    }
}

.scroll-up{
    padding: 19px 0px 0px 0px;
}

.scroll-down{
    padding: 0px 0px ;
    animation-duration: 4s;
}